import React, { useState } from "react";

const BlogsComponent = ({ count, showPagination }) => {
  const blogContent = [
    {
      id: 1,
      admin: <a href="/blogs/blogs-details-sidebar">by admin</a>,
      publisedDate: <a href="/blogs/blogs-details-sidebar">20 May, 2019</a>,
      title: (
        <a href="/blogs/blogs-details-sidebar">
          Is it Time to Audit your Employee Benefits Package?
        </a>
      ),

      readMore: (
        <a href="/blogs/blogs-details-sidebar" className="read-more">
          Read More
        </a>
      ),

      src: `${require("../../Asserts/images/blog-1-1.jpg")}`,
      icon: <i className="fa fa-arrow-right"></i>,
    },
    {
      id: 2,
      admin: <a href="/blogs/blogs-details-sidebar">by admin</a>,
      publisedDate: <a href="/blogs/blogs-details-sidebar">7 June, 2019</a>,
      title: (
        <a href="/blogs/blogs-details-sidebar">
          California Supreme Court Adopts Test Broadening
        </a>
      ),

      readMore: (
        <a href="/blogs/blogs-details-sidebar" className="read-more">
          Read More
        </a>
      ),

      src: `${require("../../Asserts/images/blog-1-2.jpg")}`,
      icon: <i className="fa fa-arrow-right"></i>,
    },
    {
      id: 3,
      admin: <a href="/blogs/blogs-details-sidebar">by admin</a>,
      publisedDate: <a href="/blogs/blogs-details-sidebar">20 May, 2019</a>,
      title: (
        <a href="/blogs/blogs-details-sidebar">
          Payonline Costs for Supplemental Payments to Employees
        </a>
      ),

      readMore: (
        <a href="/blogs/blogs-details-sidebar" className="read-more">
          Read More
        </a>
      ),

      src: `${require("../../Asserts/images/blog-1-3.jpg")}`,
      icon: <i className="fa fa-arrow-right"></i>,
    },
    {
      id: 4,
      admin: <a href="/blogs/blogs-details-sidebar">by admin</a>,
      publisedDate: <a href="/blogs/blogs-details-sidebar">20 May, 2019</a>,
      title: (
        <a href="/blogs/blogs-details-sidebar">
          Do you Prefer an Online Time and Attendacne Solution?
        </a>
      ),

      readMore: (
        <a href="/blogs/blogs-details-sidebar" className="read-more">
          Read More
        </a>
      ),

      src: `${require("../../Asserts/images/blog-1-4.jpg")}`,
      icon: <i className="fa fa-arrow-right"></i>,
    },
    {
      id: 5,
      admin: <a href="/blogs/blogs-details-sidebar">by admin</a>,
      publisedDate: <a href="/blogs/blogs-details-sidebar">7 June, 2019</a>,
      title: (
        <a href="/blogs/blogs-details-sidebar">
          A Time Clock that Works on your Time and <br />
          Budget
        </a>
      ),

      readMore: (
        <a href="/blogs/blogs-details-sidebar" className="read-more">
          Read More
        </a>
      ),

      src: `${require("../../Asserts/images/blog-1-5.jpg")}`,
      icon: <i className="fa fa-arrow-right"></i>,
    },
    {
      id: 6,
      admin: <a href="/blogs/blogs-details-sidebar">by admin</a>,
      publisedDate: <a href="/blogs/blogs-details-sidebar">7 June, 2019</a>,
      title: (
        <a href="/blogs/blogs-details-sidebar">
          Take Human Resources Compliance to a New Level
        </a>
      ),

      readMore: (
        <a href="/blogs/blogs-details-sidebar" className="read-more">
          Read More
        </a>
      ),

      src: `${require("../../Asserts/images/blog-1-6.jpg")}`,
      icon: <i className="fa fa-arrow-right"></i>,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const totalPost = blogContent.length;
  const totalPage = Math.ceil(totalPost / count);

  const indexOfLastPost = currentPage * count;
  const indexOfFirstPost = indexOfLastPost - count;
  const currentPosts = blogContent.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div>
      <section className="sec-pad blog-style-one sec-pad news-page">
        <div className="container">
          <div className="row">
            {currentPosts.slice(0, count).map((index, idx) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={idx}>
                <div className="single-blog-style-one">
                  <div className="text-box hvr-sweep-to-bottom">
                    <div className="meta-info">
                      {index.admin}

                      <span className="sep">-</span>
                      {index.publisedDate}
                    </div>

                    <h3>{index.title}</h3>
                    {index.readMore}
                  </div>

                  <div className="image-box">
                    <img src={index.src} alt="Awesome Image" />
                    <div className="box">
                      <a
                        href="/blogs/blogs-details-sidebar"
                        className="more-btn"
                      >
                        <span> {index.icon}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {showPagination && (
            <div className="post-pagination">
              {Array.from({ length: totalPage }, (_, index) => (
                <span key={index + 1} className="page-item">
                  <a
                    onClick={() => paginate(index + 1)}
                    href="#!"
                    className={currentPage === index + 1 ? "active" : ""}
                    style={currentPage === index + 1 ? { color: "red" } : {}}
                  >
                    <p
                      style={
                        currentPage === index + 1 ? { color: "white" } : {}
                      }
                    >
                      {index + 1}
                    </p>
                  </a>
                </span>
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default BlogsComponent;
