import React from "react";
import BreadCrumbs from "../../../Component/BreadCrumbs/BreadCrumbs";

const OurTeam = () => {
  const bannerImages = [
    {
      url: `${require("../../../Asserts/images/team-1-2.jpg")}`,
      icon: <i className="fa fa-twitter"></i>,
      title: <h3>Jessica Brown</h3>,
      para: <>Board Director</>,
    },
    {
      url: `${require("../../../Asserts/images/team-1-3.jpg")}`,
      icon: <i className="fa fa-youtube-play"></i>,
      title: <h3>Chris Michael</h3>,
      para: <>CO Founder</>,
    },
    {
      url: `${require("../../../Asserts/images/team-1-1.jpg")}`,
      icon: <i className="fa fa-linkedin"></i>,
      title: <h3>Sara Albert</h3>,
      para: <>Manager</>,
    },
    {
      url: `${require("../../../Asserts/images/team-1-4.jpg")}`,
      icon: <i className="fa fa-facebook"></i>,
      title: <h3>Ruben Tavil</h3>,
      para: <>Sales Officer</>,
    },
    {
      url: `${require("../../../Asserts/images/team-1-5.jpg")}`,
      icon: <i className="fa fa-twitter"></i>,
      title: <h3>Jessica Brown</h3>,
      para: <>Board Director</>,
    },
    {
      url: `${require("../../../Asserts/images/team-1-6.jpg")}`,
      icon: <i className="fa fa-twitter"></i>,
      title: <h3>Jessica Brown</h3>,
      para: <>Board Director</>,
    },
  ];
  return (
    <div>
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />
          <h1>Our Team</h1>
        </div>
      </div>

      <section className="team-page sec-pad">
        <div className="container">
          <div className="row">
            <div className="sec-title">
              <h2>Meet Our Team</h2>
            </div>
            <div className="row">
              {bannerImages.map((img, idx) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="single-team-style-one">
                    <div className="top-box">
                      <div className="img-box">
                        <img src={img.url} alt="Awesome Image" />
                      </div>
                      <div className="social">
                        <a href="#">{img.icon}</a>
                      </div>
                    </div>
                    <div className="text-box">
                      <h3>
                        <a href="#">{img.title}</a>
                      </h3>
                      <p>{img.para}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurTeam;
