import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import $ from "jquery"; // Ensure jQuery is imported

// CSS imports
import "../src/Asserts/css/animate.css";
import "../src/Asserts/css/bootstrap-select.min.css";
import "../src/Asserts/css/bootstrap.min.css";
import "../src/Asserts/css/font-awesome.min.css";
import "../src/Asserts/css/hover-min.css";
import "../src/Asserts/css/jquery.bxslider.min.css";
import "../src/Asserts/css/magnific-popup.css";
import "../src/Asserts/css/owl.carousel.css";
import "../src/Asserts/css/owl.theme.default.min.css";
import "../src/Asserts/css/responsive.css";
import "../src/Asserts/css/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
