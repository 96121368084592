import React from "react";
import BreadCrumbs from "../../Component/BreadCrumbs/BreadCrumbs";

const BlogsDetailsSidebar = () => {
  return (
    <div>
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />

          <h1>Single Blogs</h1>
        </div>
      </div>

      <section className="sec-pad blog-style-one sec-pad news-page news-page-sidebar single-blog-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="featured-image-box">
                <img
                  src={require("../../Asserts/images/single-blog-1-2.jpg")}
                  alt="Awesome Image"
                />
              </div>

              <div className="text-block">
                <h3>
                  California Supreme Court Adopts Test Broadening Definition of
                  Employee
                </h3>
                <div className="meta-info">
                  <a href="#">by admin</a> <span className="sep">-</span>{" "}
                  <a href="#">20 May, 2019</a> <span className="sep">-</span>{" "}
                  <a href="#">2 Comments</a>
                </div>

                <p>
                  Lorem Ipsum is simply dummy text of the rinting and
                  typesetting industry has been the ndustry standard dummy text
                  ever sincer they llam id condimentum purus In non ex at ligula
                  fringilla bortis. Ut et mauris auctor, aliquet nulla sed,
                  aliquam mauris. Vestibulum sed malesuada dolor. Integer
                  fringilla odio a dolor aliquet, eu euismod lectus porttitor.
                  Proin et libero nec eros eleifend commodo Phasellus sodales
                  des volutpat diam, id sagittis purus egestas dapibus. Donec
                  bibendum est quis mi commodo blandit. Maecenas pellentesque,
                  massa vitae faucibus consectetur, ante magna gravida magna, ut
                  venenatis massa augue et odio. Aliquam luctus viverra velit,
                  non euismod ligula congue sed.
                </p>
                <br />
                <p>
                  Mauris nunc leo, sollicitudin a ligula ut, iaculis bibendum
                  lorem. Duis gravida suscipit purus, at consequat de diam
                  sagittis sit amet. Mauris sed nisl vel urna egestas elementum
                  eget quis ipsum. Nulla a enim et justoed facilisis ornare. Sed
                  ante sem, dignissim a vehicula et, rutrum volutpat turpis.
                  Praesent id leo lacinia lesuada tortor ut, lobortis leo. Cras
                  eget sollicitudin lorem. Etiam commodo ultricies luctus.
                  Integer porttitor ligula eget quam blandit finibus.
                  Suspendisse potenti. Nulla blandit augue orci, eget tristique
                  massa fermentum sed duis ac maximus nulla, et pharetra turpis.
                  Maecenas odio tortor, egestas sit amet erat nec, maximus
                  mattis exque purus nibh, porttitor nec aliquam id, auctor a
                  nulla. Curabitur sed pharetra ligula. Donec nequey odio
                  derdiet non libero ornare, commodo venenatis magna praesent ac
                  congue ligula.
                </p>
              </div>

              <div className="share-block clearfix">
                <div className="tags-block float-left">
                  <span>Tags:</span>
                  <a href="#">payroll</a>,<a href="#">employees</a>,
                  <a href="#">hiring</a>,<a href="#">startups</a>
                </div>

                <div className="social-block float-right">
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </div>
              </div>

              <div className="author-block">
                <div className="author-img-box">
                  <img
                    src={require("../../Asserts/images/author-1-1.jpg")}
                    alt="Awesome Image"
                  />
                </div>

                <div className="author-text-block">
                  <h3>Tricia Pritzel</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the rinting and
                    typesetting been the industry standard dummy text uctor a
                    nulla.
                  </p>
                  <a href="#" className="read-more">
                    View all posts
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="sidebar">
                <div className="single-sidebar search-widget">
                  <form action="#" className="search-form">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search here..."
                    />
                    <button type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>

                <div className="single-sidebar latest-post-widget">
                  <div className="title-box">
                    <h3>Latest Posts</h3>
                  </div>

                  <div className="single-latest-post">
                    <div className="img-box">
                      <img
                        src={require("../../Asserts/images/recent-post1-1.jpg")}
                        alt="Awesome Image"
                      />
                    </div>

                    <div className="text-box">
                      <h3>
                        <a href="#">
                          Is it Time to Audit your Employee Benefits?
                        </a>
                      </h3>
                    </div>
                  </div>

                  <div className="single-latest-post">
                    <div className="img-box">
                      <img
                        src={require("../../Asserts/images/recent-post1-2.jpg")}
                        alt="Awesome Image"
                      />
                    </div>

                    <div className="text-box">
                      <h3>
                        <a href="#">Payonline Costs for Suplemental Payments</a>
                      </h3>
                    </div>
                  </div>

                  <div className="single-latest-post">
                    <div className="img-box">
                      <img
                        src={require("../../Asserts/images/recent-post1-3.jpg")}
                        alt="Awesome Image"
                      />
                    </div>

                    <div className="text-box">
                      <h3>
                        <a href="#">
                          A Time Clock that Works on your Time & Budget
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="single-sidebar categories-widget">
                  <div className="title-box">
                    <h3>Categories</h3>
                  </div>

                  <ul className="links-list">
                    <li>
                      <a href="#">Startup</a>
                    </li>
                    <li className="active">
                      <a href="#">Payroll/Taxes</a>
                    </li>
                    <li>
                      <a href="#">Human Resources</a>
                    </li>
                    <li>
                      <a href="#">Employee Benefits</a>
                    </li>
                    <li>
                      <a href="#">Business Insurance</a>
                    </li>
                    <li>
                      <a href="#">Compliance</a>
                    </li>
                  </ul>
                </div>

                <div className="single-sidebar tags-widget">
                  <div className="title-box">
                    <h3>Tags</h3>
                  </div>

                  <ul className="tags-list">
                    <li>
                      <a href="#">payroll</a>,
                    </li>
                    <li>
                      <a href="#">employee</a>,
                    </li>
                    <li>
                      <a href="#">hiring</a>,
                    </li>
                    <li>
                      <a href="#">startup</a>,
                    </li>
                    <li>
                      <a href="#">business</a>,
                    </li>
                    <li>
                      <a href="#">compliance</a>,
                    </li>
                    <li>
                      <a href="#">resource</a>,
                    </li>
                    <li>
                      <a href="#">benefits</a>,
                    </li>
                    <li>
                      <a href="#">insurance</a>,
                    </li>
                  </ul>
                </div>

                <div className="single-sidebar subscribe-widget">
                  <div className="title-box">
                    <h3>Monthly Newsletter</h3>
                  </div>

                  <form action="#" className="sidebar-subscribe-form">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email address"
                    />
                    <button type="submit" className="thm-btn">
                      Subscribe newsletter
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogsDetailsSidebar;
