import React, { useState } from "react";
import PopUpForm from "../PopUpForm/PopUpForm";

const Header = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const menuNavBar = [
    {
      url: "/contact-us",
      menu: "Contact Us",
      submenu: [],
    },
    {
      url: "/blogs",
      menu: "Blogs",
      submenu: [],
    },
    {
      url: "/career",
      menu: "Career",
      submenu: [],
    },
    {
      url: "#",
      menu: "Company",
      submenu: [
        {
          url: "/company/about-us",
          submenu: "About Us",
        },
        {
          url: "/company/testimonials",
          submenu: "Testimonials",
        },
        // {
        //   url: "/company/our-team",
        //   submenu: "Our Team",
        // },
      ],
    },
    {
      url: "#",
      menu: "Services",
      submenu: [
        {
          url: "/services/accounting-solution",
          submenu: "Accounting Solution",
        },
        {
          url: "/services/recruitment-staffing",
          submenu: "Recruitment: Staffing",
        },
        {
          url: "/services/payroll-solution",
          submenu: "Payroll Solution",
        },
      ],
    },
    {
      url: "/",
      menu: "Home",
      submenu: [],
    },
  ];
  return (
    <header class="site-header ">
      <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky header-style-two">
        <div class="container clearfix">
          <div class="logo-box clearfix">
            <a class="navbar-brand" href="/">
              <img
                className="shieldit-logo"
                src={require("../../Asserts/images/logo-1-1.png")}
                alt="Awesome Image"
              />
            </a>
            <button class="menu-toggler" data-target="#main-nav-bar">
              <span class="fa fa-bars"></span>
            </button>
          </div>
          {menuNavBar.map((menuMap, index) => (
            <div className="main-navigation" id="main-nav-bar">
              <ul className="navigation-box">
                <li className={menuMap.submenu.length > 0 ? "dropdown" : ""}>
                  <a href={menuMap.url}>{menuMap.menu}</a>
                  {menuMap.submenu.length > 0 && (
                    <ul className="sub-menu">
                      {menuMap.submenu.map((sub, subIndex) => (
                        <li key={subIndex}>
                          <a href={sub.url}>{sub.submenu}</a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          ))}
        </div>

        <button onClick={togglePopup} class="current bgcolor">
          Request Meeting
        </button>
        <PopUpForm show={showPopup} handleClose={togglePopup} />
      </nav>
    </header>
  );
};

export default Header;
