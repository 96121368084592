import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import Brand from "../../../Component/Brand/Brand";
import BreadCrumbs from "../../../Component/BreadCrumbs/BreadCrumbs";

const AboutUs = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [hasAnimated, setHasAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        setHasAnimated(true);
      }
    },
  });

  const handlePagerClick = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const bannerImages = [
    {
      url: `${require("../../../Asserts/images/testi-2-1-m.jpg")}`,
      title: <>Stefan Schmertz, Managing Partne</>,
      para: (
        <>
          Payroll has to be done right. If the client has problems with payroll
          and the provider, it comes right back to us. We know that when they go
          to Payonline, they’re going to be taken care of and everything’s done.
        </>
      ),

      list1: "Juravel & Company, LLC",
      list2: "Business Size: 1-9 Employees",
      list3: "Solutions: Bookkeeping, Tax Services, Small Business Consulting",
    },
    {
      url: `${require("../../../Asserts/images/testi-2-2-m.jpg")}`,
      title: <>Stefan Schmertz, Managing Partne</>,
      para: (
        <>
          an you make the logo bigger yes bigger bigger still the logo is too
          big can you pimp this powerpoint, need more geometry patterns. I love
          it, but can you invert all colors? there are more projects lined up
          charge extra?
        </>
      ),

      list1: "Juravel & Company, LLC",
      list2: "Business Size: 1-9 Employees",
      list3: "Solutions: Bookkeeping, Tax Services, Small Business Consulting",
    },
    {
      url: `${require("../../../Asserts/images/testi-2-3-m.jpg")}`,
      title: <>Stefan Schmertz, Managing Partne</>,
      para: (
        <>
          an you make the logo bigger yes bigger bigger still the logo is too
          big can you pimp this powerpoint, need more geometry patterns. I love
          it, but can you invert all colors? there are more projects lined up
          charge extra?
        </>
      ),

      list1: "Juravel & Company, LLC",
      list2: "Business Size: 1-9 Employees",
      list3: "Solutions: Bookkeeping, Tax Services, Small Business Consulting",
    },
    {
      url: `${require("../../../Asserts/images/testi-2-4-m.jpg")}`,
      title: <>Stefan Schmertz, Managing Partne</>,
      para: (
        <>
          an you make the logo bigger yes bigger bigger still the logo is too
          big can you pimp this powerpoint, need more geometry patterns. I love
          it, but can you invert all colors? there are more projects lined up
          charge extra?
        </>
      ),

      list1: "Juravel & Company, LLC",
      list2: "Business Size: 1-9 Employees",
      list3: "Solutions: Bookkeeping, Tax Services, Small Business Consulting",
    },
  ];
  return (
    <div>
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />
          <h1>About Shield IT</h1>
        </div>
      </div>

      <section className="about-style-one sec-pad ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="about-content my-auto">
                <div className="sec-title text-center">
                  <span>About Shield IT</span>
                  <h2>
                    Expert Payroll, HR, and <br />
                    Accounting Solutions for Businesses
                  </h2>
                </div>

                <p>
                  At Shield IT, we specialize in providing top-notch payroll,
                  HR, and accounting solutions. Our expert team ensures
                  accuracy, compliance, and efficiency, helping your business
                  streamline operations and achieve its goals. Trust Shield IT
                  for reliable, comprehensive services tailored to your needs.
                </p>
                <a href="#" className="thm-btn">
                  Get Immedate service
                </a>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="featured-image-box">
                <div className="img-box">
                  <img
                    src={require("../../../Asserts/images/hiring-service-1-1.jpg")}
                    alt="Awesome Image"
                  />
                </div>

                <div className="text-box">
                  <h3>
                    Streamlining Your Business Operations with Accuracy,
                    Compliance, and Efficiency
                  </h3>
                  <p>
                    Shield IT offers expert payroll, HR, and accounting
                    solutions, ensuring your business operates smoothly with
                    precision, regulatory compliance, and enhanced efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fun-fact-style-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="sec-title">
                <span>fun facts</span>
                <h2>
                  Secure, Compliant <br />
                  and easy
                </h2>
              </div>
            </div>

            <div className="col-lg-8" ref={ref}>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="single-fun-fact-style-one">
                    <h3 className="counter">
                      {hasAnimated ? <CountUp end={860} /> : "860"}
                    </h3>
                    <p>Projects completed</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="single-fun-fact-style-one">
                    <h3 className="counter">
                      {hasAnimated ? <CountUp end={50} /> : "50"}
                    </h3>
                    <p>Active clients</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="single-fun-fact-style-one">
                    <h3 className="counter">
                      {hasAnimated ? <CountUp end={53} /> : "53"}+
                    </h3>
                    <p>Ongoing Projects</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="single-fun-fact-style-one">
                    <h3 className="counter">
                      {hasAnimated ? <CountUp end={970} /> : "970"}+
                    </h3>
                    <p>Happy Customers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials-style-two">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          controls={false}
          className="slider-content-style slider-home-one"
        >
          {bannerImages.map((img, idx) => (
            <Carousel.Item key={idx}>
              <div
                className="single-testimonial-two sec-pad"
                style={{
                  backgroundImage: `url(${img.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="text-box">
                        <i className="payonline-icon-left-quotes-sign"></i>
                        <p>{img.para}</p>
                        <h3>{img.title}</h3>
                        <ul className="list-items">
                          <li>{img.list1}</li>
                          <li>{img.list2}</li>
                          <li>{img.list3}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="container">
          <div className="testi-pager row" id="testi-pager">
            {bannerImages.map((img, idx) => (
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" key={idx}>
                <a
                  href="#"
                  className={`pager-item ${index === idx ? "active" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePagerClick(idx);
                  }}
                  data-slide-index={idx}
                >
                  <div className="testi-thumb">
                    <img
                      src={require(`../../../Asserts/images/testi-2-${
                        idx + 1
                      }-t.jpg`)}
                      alt="Awesome Image"
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="team-style-one sec-pad">
        <div className="container">
          <div className="sec-title text-center">
            <span>people behind the success</span>
            <h2>
              Meet our expert people <br />
              of payonline
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-team-style-one">
                <div className="top-box">
                  <div className="img-box">
                    <img
                      src={require("../../../Asserts/images/team-1-2.jpg")}
                      alt="Awesome Image"
                    />
                  </div>

                  <div className="social">
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </div>
                </div>

                <div className="text-box">
                  <h3>
                    <a href="#">Jessica Brown</a>
                  </h3>
                  <p>Board Director</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-team-style-one">
                <div className="top-box">
                  <div className="img-box">
                    <img
                      src={require("../../../Asserts/images/team-1-3.jpg")}
                      alt="Awesome Image"
                    />
                  </div>

                  <div className="social">
                    <a href="#">
                      <i className="fa fa-youtube-play"></i>
                    </a>
                  </div>
                </div>

                <div className="text-box">
                  <h3>
                    <a href="#">Chris Michael</a>
                  </h3>
                  <p>CO Founder</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-team-style-one">
                <div className="top-box">
                  <div className="img-box">
                    <img
                      src={require("../../../Asserts/images/team-1-1.jpg")}
                      alt="Awesome Image"
                    />
                  </div>

                  <div className="social">
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                </div>

                <div className="text-box">
                  <h3>
                    <a href="#">Sara Albert</a>
                  </h3>
                  <p>Manager</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-team-style-one">
                <div className="top-box">
                  <div className="img-box">
                    <img
                      src={require("../../../Asserts/images/team-1-4.jpg")}
                      alt="Awesome Image"
                    />
                  </div>

                  <div className="social">
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </div>
                </div>

                <div className="text-box">
                  <h3>
                    <a href="#">Ruben Tavil</a>
                  </h3>
                  <p>Sales Officer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Brand /> */}
    </div>
  );
};

export default AboutUs;
