import React, { useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    file: null,
    file_url: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormData({
        ...formData,
        file: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Step 1: Upload the file to CPanel
    const data = new FormData();
    data.append("file", formData.file);

    try {
      const uploadResponse = await axios.post(
        "https://new.shieldit.co.in/upload.php",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.data.status === "success") {
        const file_url = uploadResponse.data.file_url;

        // Step 2: Send email using EmailJS
        const templateParams = {
          user_name: formData.user_name,
          user_email: formData.user_email,
          message: "You have received a new file attachment.",
          file_url: file_url,
        };

        const emailResponse = await emailjs.send(
          "service_59mygim",
          "template_yeoxc6l",
          templateParams,
          "1APIgSiYMwbuckOPE"
        );
        console.log(
          "Email sent successfully!",
          emailResponse.status,
          emailResponse.text
        );
        alert("File uploaded and email sent successfully");
      } else {
        console.error("All ready file is uploaded:", uploadResponse.data);
        alert("All ready file is uploaded");
      }
    } catch (error) {
      console.error("Error uploading the file:", error);
      alert("Error uploading the file");
    }
  };

  return (
    <>
      <div className="inner-banner text-center">
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <span>Career</span>
            </li>
          </ul>

          <h1>Career</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="user_name">Name:</label>
        <input
          type="text"
          id="user_name"
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
          required
        />
        <br />
        <br />

        <label htmlFor="user_email">Email:</label>
        <input
          type="email"
          id="user_email"
          name="user_email"
          value={formData.user_email}
          onChange={handleChange}
          required
        />
        <br />
        <br />

        <label htmlFor="file">Attachment:</label>
        <input
          type="file"
          id="file"
          name="file"
          onChange={handleChange}
          required
        />
        <br />
        <br />

        <button type="submit">Send</button>
      </form>
    </>
  );
};

export default ContactForm;
