import React from "react";
import BreadCrumbs from "../../Component/BreadCrumbs/BreadCrumbs";
import BlogsComponent from "../../Component/BlogsComponent/BlogsComponent";

const Blogs = () => {
  return (
    <div>
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />

          <h1>Latest Blogs</h1>
        </div>
      </div>

      <BlogsComponent count={3} />
    </div>
  );
};

export default Blogs;
