import React, { useRef } from "react";
import emailjs from "emailjs-com";
import BreadCrumbs from "../../Component/BreadCrumbs/BreadCrumbs";

const ContactUs = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        form.current,
        "YOUR_USER_ID"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send email.");
        }
      );
  };

  return (
    <div>
      {" "}
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />
          <h1>Contact</h1>
        </div>
      </div>
      {/* Address Start */}
      <section className="contact-page-infos sec-pad-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-contact-page-info">
                <div className="icon-box">
                  <span>
                    {" "}
                    <i className="fa fa-map"></i>
                  </span>
                </div>
                <p>Keshav nagar mundwa, Pune Maharastra</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-contact-page-info">
                <div className="icon-box">
                  <span>
                    <i className="fa fa-phone "></i>
                  </span>
                </div>
                <p>
                  Mobile: <br />
                  +91 78758 55559
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-contact-page-info">
                <div className="icon-box">
                  <span>
                    <i className="fa fa-envelope"></i>
                  </span>
                </div>
                <p>
                  Email: <br />
                  info@shieldit.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Address End */}
      {/* ======================================================= */}
      {/* Form Start */}
      <section className="contact-page-content sec-pad">
        <div className="container">
          <div className="sec-title text-center">
            <span>Contact with us</span>
            <h2>
              Let’s meet with our expert <br />
              team members
            </h2>
          </div>

          <form
            ref={form}
            onSubmit={handleSubmit}
            className="meeting-form contact-form"
          >
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="col-md-6">
                <select
                  className="selectpickers dropdown-menuu"
                  name="discussion"
                  required
                >
                  <option value="option1">CHOOSE SERVICE</option>
                  <option value="Payroll Solution">Payroll Solution</option>
                  <option value="Staffing Solution">Staffing Solution</option>
                  <option value="Accounting Solution">
                    Accounting Solution
                  </option>
                </select>
              </div>
              <div className="col-md-12">
                <textarea
                  name="message"
                  placeholder="Comment or questions"
                  required
                ></textarea>
              </div>
              <div className="col-md-12">
                <div className="btn-box">
                  <button type="submit" className="thm-btn">
                    Request a free demo
                  </button>
                  <span className="btn-tag-line">
                    Start the conversation
                    <i className="payonline-icon-share"></i>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* Form End  */}
      {/* ======================================================== */}
      <div className="google-map" id="contact-google-map">
        <iframe
          className="mapcontact"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3867187.666169696!2d76.76983739999999!3d18.81817715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707508598139!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
